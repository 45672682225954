// Place all the styles related to the static controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

header.splash {
  @extend .px-md-4;
  @extend .d-flex;
  flex-direction: column;
  justify-content: end;

  margin-top: -1rem;

  width: 100%;
  height: 500px;

  video {
    position: absolute;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: -10;
    object-fit: cover;

    background: transparent url('../images/splash-masthead.png') no-repeat 0 0;
  }

  .logo {
    background-image: url('../images/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 200px;
  }
}

.rotating-list-container {
  overflow: hidden;
  height: 1.2em; // I want to calculate this, but I'm not sure what to base it on

  > div {
    @extend .d-inline;
    @extend .pe-2;
    float: left;
  }

  .list {
    @extend .h1;
    @extend .d-inline-block;

    font-family: $font-family-sans-serif;
    font-weight: bold;
    list-style-type: none;
    padding-left: 0;

    -webkit-animation-name: change;
    -webkit-animation-duration: 9s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
}

section.splash {
  @extend .pt-3;
  @extend .text-light !optional; /** FIXME: This should work... */
}

// 4s on each line
// 4x + 4y = 100%
// x = 8, y = 17

@keyframes change {
  0%, 17%, 100% {transform:translate3d(0,0,0);}
  25%, 42% {transform:translate3d(0,-25%,0);}
  50%, 67% {transform:translate3d(0,-50%,0);}
  75%, 92% {transform:translate3d(0,-75%,0);}
}

// BEGIN styles for the changelog
$changelog-icons: (
  "new-feature": "⭐",
  "bug-fix": "🐛",
  "maintenance": "⚙️"
);

@each $name, $emoji in $changelog-icons {
  li.#{$name}:before {
    display: inline-block;
    margin-right: .3em;
    content: $emoji;
  }
}

// BEGIN Toast logic
cta-toastholder {
  @extend .m-2;

  position: fixed;
  top: calc(#{($navbar-padding-y * 2) + ($nav-link-padding-y * 2)} + (#{$line-height-base} * #{$font-size-base}));
  bottom: 0;
  right: 0;
  width: 350px;
  pointer-events: none;
  z-index: 1200;

  ngb-toast {
    pointer-events: initial;
  }
}

$navbar-height: 3.5rem;
$footer-height: 3.5rem;

main.container, main.container-fluid {
  margin-top: calc(#{$navbar-height} - 1px);
  min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - 1px);
  padding-top: 1rem;
  padding-bottom: 1rem;

  & + footer {
    height: $footer-height;
  }
}

.split-view {
  @extend .row;

  > div {
    @extend .p-4;
    @include media-breakpoint-up(md) {
      height: calc(100vh - 3.5rem);
      overflow-y: auto;
    }
  }
}

.pane-upcoming-events {
  @extend .bg-default;

  width: 100%;

  @include media-breakpoint-up(md) {
    width: 320px;
  }
  // TODO: Responsive sizing
}
