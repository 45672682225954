$white: #ffffff;
$gray-200: #e9ecef !default;
$link-decoration: none;

$light: $gray-200;

$custom-colors: (
  "default": $white
);

$header-height: 300px;

$body-bg: #1c1b20;
$input-bg: $white; /** This changed in Bootstrap 5, I think */
$dark: #28272e;

// Disable rounded corners
$enable-rounded: true;
$spacer: .8rem;
// $line-height-base: 1.25;

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('../fonts/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('../fonts/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/FiraSans-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/FiraSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/FiraSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/FiraSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/FiraSans-Bold.woff2') format('woff2');
}

$font-family-sans-serif: 'Fira Sans';
$headings-font-family: 'SpaceGrotesk';

.hero-header {
  height: $header-height;
  margin-top: -15px !important; // FIXME: swap this out for a Bootstrap variable; there's also conflicts elsewhere in Bootstrap 5
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-align: 50% 50%;

  .col {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.show-on-focus, .show-on-hover {
  margin: 0;
  clip: rect(1px,1px,1px,1px);
}

.show-on-focus:focus, .show-on-hover:hover, .show-on-hover:hover > .hover-hidden {
  z-index: 20;
  width: auto;
  height: auto;
  clip: auto;
}

.show-on-focus, .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
