$icon-width: 64px;
$event-line-height: 1.0;

.event-game-icon {
  width: $icon-width;

  > img {
    @include border-radius($card-border-radius);
  }
}

.event-date-time {
  @extend .p-1;
  line-height: $event-line-height;
  width: 13ch;
}

.event-details {
  @extend .col-sm;
  @extend .col-12;
  @extend .p-1;
  line-height: $event-line-height;
}

.event-actions {
  @extend .p-1;
  @extend .text-center;
  @extend .col-12;
  @include media-breakpoint-up(sm) {
    flex-basis: min-content;
  }
  line-height: $event-line-height;
}
