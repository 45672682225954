// Place all the styles related to the games controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

$game-size: 200px;
$triangle-color: #0d76e0; // TODO: move elsewhere?

.game-card {
  @extend .mb-3;
  @include media-breakpoint-up(md) {
    flex-basis: $game-size;
    min-height: $game-size * (4/3);
  }

  div.card-img {
    height: 0;
    width: 100%;
    overflow: hidden;
    padding-top: 133.33%;
    position: relative;

    .inner-card {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: #f8f8f8;
      border: 10px solid #ddd;
      color: #ccc;
      transition: 0.3s;

      &:hover {
        background: #eee;
        border: 10px solid #bbb;
        color: #888;
      }
    }
  }

  h6 {
    font-size: .8em;
  }
}

.in-library {
  right: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0;
  border-right: $game-size / 4 solid $triangle-color;
  border-bottom: $game-size / 4 solid transparent;
  z-index: 1; /** Need to bring this on top of div.card-img */
  border-top-right-radius: valid-radius($card-border-radius);
 
  .fa-star {
    position: absolute;
    left: 2.0em;
    top: .5em;
    color: #e9ecef;
  }
}
